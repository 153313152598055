interface HeaderProps {
  onToggleTheme: () => void;
  theme: boolean;
}

const Header: React.FC<HeaderProps> = ({ onToggleTheme, theme }) => (
  <header className="shadow-md shadow-border py-3 px-20 flex flex-wrap justify-around sm:justify-between flex-center">
    <a className="flex" href="/">
      <img src="https://umeeversity.umee.cc/img/Logo.svg" alt="logo" />
      <p className="ml-2 title text-5xl">faucet</p>
    </a>
    <div className="flex">
      <button className="gradient-border rounded-md transition-shadow hover:shadow-cyan-400/50 hover:shadow-md">
        <div className="px-4">umee-canon-4</div>
      </button>
      <button className="my-auto ml-4" onClick={onToggleTheme}>
        <img
          src={`https://umeeversity.umee.cc/img/${
            theme ? "dark-mode" : "light-mode"
          }.svg`}
          alt="theme"
          className="w-8"
        />
      </button>
    </div>
  </header>
);

Header.defaultProps = {
  theme: false,
};

export default Header;
