import { useState, useCallback } from 'react'
import Footer from 'components/footer'
import Header from 'components/header'
import clsx from 'clsx'

interface LayoutProps {
  children?: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [theme, setTheme] = useState<boolean>(
    () => localStorage.getItem('theme') === 'dark'
  )

  const handleToggleTheme = useCallback(() => {
    setTheme(prev => !prev)
    localStorage.setItem('theme', !theme ? 'dark' : 'false')
  }, [theme])

  return (
    <div
      aria-label="layout"
      className={clsx(
        {'dark-theme': theme},
        'flex flex-col bg-background transition-colors h-screen'
      )}
    >
      <Header onToggleTheme={handleToggleTheme} theme={theme} />
      {children}
      <Footer />
    </div>
  )
}

export default Layout
