const apiPath = process.env.REACT_APP_API_PATH || ''

export const signOutTwitter = (oauthToken: string) => {
  return fetch(
    `${apiPath}/twitter/logout`,
    { method: 'POST', headers: { oauth_token: oauthToken } }
  )
}

export const getProfile = (oauth_token: string) => {
  return fetch(`${apiPath}/twitter/users/profile_banner`, { headers: { oauth_token }})
}
