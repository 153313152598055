import { useEffect, useState, useCallback, useRef } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import Layout from "components/layout";
import * as sso from "lib/twitter-sso";
import { fetch200, faucet } from "lib/api";
import clsx from "clsx";
import * as yup from "yup";
import connectKeplr from "./connectKeplr";

interface SpinnerProps {
  className?: string;
}

const Spinner = ({ className }: SpinnerProps) => (
  <svg
    className={clsx(className, "animate-spin -ml-1 mr-3 h-5 w-5 text-white")}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <circle
      className="opacity-25"
      cx="12"
      cy="12"
      r="10"
      stroke="currentColor"
      strokeWidth="4"
    ></circle>
    <path
      className="opacity-75"
      fill="currentColor"
      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
    ></path>
  </svg>
);

const schema = yup.object({
  address: yup
    .string()
    .required("Required")
    .matches(/^umee[a-zA-Z0-9]*/, "Invalid address")
    .length(43, "Invalid length"),
});

const secondsToHms = (sec: number) => {
  const h = Math.floor(sec / 3600);
  const m = Math.floor((sec % 3600) / 60);
  const s = Math.floor((sec % 3600) % 60);

  const hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  const mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  const sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";

  return hDisplay + mDisplay + sDisplay;
};

const App = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [profile, setProfile] = useState<string | null>(null);
  let [searchParams] = useSearchParams();

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });
  const navigate = useNavigate();
  const timer = useRef<number>();
  const [remainingTillFaucet, setRemainingTillFaucet] = useState<number>(0);

  useEffect(() => {
    connectKeplr();
  }, []);

  useEffect(() => {
    if (timer.current) {
      clearInterval(timer.current);
    }
    timer.current = setInterval(
      () => setRemainingTillFaucet((prev) => prev - 1),
      1000
    ) as unknown as number;
    return () => clearInterval(timer.current);
  }, []);

  const signInToTwitter = () => {
    window.location.href = `https://faucet-ocstrhuppq-uc.a.run.app/twitter/auth`;
  };

  const signOutTwitter = useCallback(() => {
    const oauthToken = localStorage.getItem("oauth_token");
    if (oauthToken) {
      sso.signOutTwitter(oauthToken).finally(() => {
        localStorage.removeItem("oauth_token");
        setProfile(null);
      });
    } else {
      setProfile(null);
    }
  }, []);

  const onSubmit = useCallback(async (data: any) => {
    const oauthToken = localStorage.getItem("oauth_token");
    if (oauthToken) {
      const response = await fetch(
        `https://cors-anywhere-ocstrhuppq-uc.a.run.app/https://fonzie-ocstrhuppq-uc.a.run.app/?wallet=${data.address}`
      ).then((response) => response);
      console.log("FAUCET RESPONSE:::", response);
      if (response.status === 200) {
        setLoading(false);
        setRemainingTillFaucet(200);
      }

      // return fetch(`https://fonzie-ocstrhuppq-uc.a.run.app/?wallet=${data.address}`).then((res)=>{
      //   console.log('FAUCET RESPONSE:::', res)
      //   if (res.status === 200) {
      //     setLoading(false)
      //   }
      // }).catch((e)=>{
      //   console.log('FAUCET ERROR:::', e)
      //   setLoading(false)
      // })
      // setLoading(true);
      // fetch200(faucet(data.address as string, oauthToken))
      //   .then((res) => setRemainingTillFaucet(res.faucet_limit_remain))
      //   .finally(() => setLoading(false));
    }
  }, []);

  useEffect(() => {
    const token = searchParams.get("oauth_token");
    const profile = searchParams.get("profile");
    console.log("TOKEN:::", token);
    console.log("Profile:::", profile);
    if (token) {
      localStorage.setItem("oauth_token", token as string);
      localStorage.setItem("profile", profile as string);
    }
    if (token) {
      setLoading(true);
      setProfile(profile);
      setLoading(false);
    }
  }, [navigate]);

  return (
    <Layout>
      <div className="p-10 flex flex-1">
        <div className="mx-auto w-96">
          <p className="title text-4xl mb-4">Request Tokens</p>
          <div className="rounded-lg border-border border-2 border-solid bg-background-secondary p-5">
            {profile === null ? (
              <>
                <p className="mb-4">
                  To prevent faucet bottling, you must sign in with Twitter. We
                  request read-only access.
                </p>
                <button
                  className="primary w-full"
                  disabled={loading}
                  onClick={signInToTwitter}
                >
                  {loading && <Spinner />} Sign in with Twitter
                </button>
              </>
            ) : remainingTillFaucet > 0 ? (
              <>
                <p className="mb-4">
                  You have successfully claimed tokens. You can request again in{" "}
                  {secondsToHms(remainingTillFaucet)}.
                </p>
                <div className="gradient-border rounded-md mb-4">
                  <input
                    className="bg-transparent p-3 w-full text-typography-light"
                    value={getValues("address")}
                    disabled
                  />
                </div>
                <button className="primary w-full mb-4" disabled>
                  Tokens Claimed
                </button>
                <p
                  className="text-typography-light text-center hover:underline cursor-pointer"
                  onClick={signOutTwitter}
                >
                  Sign out @{profile}
                </p>
              </>
            ) : (
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="gradient-border rounded-md">
                  <input
                    {...register("address")}
                    className="bg-transparent p-3 w-full"
                    placeholder="Enter umee address here..."
                    autoFocus
                  />
                </div>
                <p className="mt-2 mb-4 text-red-500">
                  <small>{errors.address?.message as unknown as string}</small>
                </p>
                <button
                  type="submit"
                  className="primary w-full mb-4"
                  disabled={loading}
                >
                  {loading && <Spinner />} Request Tokens
                </button>
                <p
                  className="text-typography-light text-center hover:underline cursor-pointer"
                  onClick={signOutTwitter}
                >
                  Sign out @{profile}
                </p>
              </form>
            )}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default App;
