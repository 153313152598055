const socials = [
  ['https://umeeversity.umee.cc/img/medium.svg', 'https:/medium.com/umeeblog', 'medium'],
  ['https://umeeversity.umee.cc/img/twitter.svg', 'https://twitter.com/Umee_CrossChain', 'twitter'],
  ['https://umeeversity.umee.cc/img/discord.svg', 'https://discord.gg/umee', 'discord'],
  ['https://umeeversity.umee.cc/img/github.svg', 'https://github.com/umee-network', 'github'],
  ['https://umeeversity.umee.cc/img/telegram.svg', 'https://t.me/umeecrosschain', 'telegram'],
  ['https://umeeversity.umee.cc/img/reddit.svg', 'https://www.reddit.com/r/UmeeCrossChain', 'reddit'],
]

const Footer: React.FC = () => (
  <div aria-label="footer" className="px-5 py-10 sm:px-20 grid md:grid-cols-2 gap-10 bg-background dark-theme border-t-2 border-solid border-t-border">
    <div className="w-64">
      <img src="https://umeeversity.umee.cc/img/umee-logo.svg" alt="umee logo" className="w-36" />
      <p className="text-typography-light mt-3">
        Join us in building the future of cross chain DeFi!
      </p>
      <div className="grid grid-cols-6 gap-1 mt-3" aria-label="socials">
        {socials.map(([ img, link, name ], key) => (
          <a
            key={key}
            target='blank'
            className='w-9 h-9 rounded-full flex border-solid border-2 border-slate-600 hover:gradient-border'
            href={link}
          >
            <img src={img} alt={name} className='m-auto' />
          </a>
        ))}
      </div>
    </div>
    <div className="grid sm:grid-cols-3 gap-3">
      <div>
        <p className="gradient-border-image border-solid border-b-2 inline-flex mb-4">
          PROTOCOL
        </p>
        <a href="https://app.umee.cc" target='blank' className="text-typography-light mb-2 block hover:underline">
          Markets
        </a>
        <a href="https://wallet.keplr.app/#/umee/governance" target='blank' className="text-typography-light mb-2 block hover:underline">
          Governance
        </a>
        <a href="https://www.umee.cc/umeemania" target='blank' className="text-typography-light mb-2 block hover:underline">
          Umeemania
        </a>
        <a href="https://github.com/umee-network" target='blank' className="text-typography-light mb-2 block hover:underline">
          Github
        </a>
      </div>

      <div>
        <p className="gradient-border-image border-solid border-b-2 inline-flex mb-4">
          DOCUMENTATION
        </p>
        <a href="https://docs.umee.cc/umee/" target='blank' className="text-typography-light mb-2 block hover:underline">
          Docs
        </a>
        <a href="https://www.umee.cc/faqs" target='blank' className="text-typography-light mb-2 block hover:underline">
          Faq
        </a>
      </div>

      <div>
        <p className="gradient-border-image border-solid border-b-2 inline-flex mb-4">
          CONNECT
        </p>
        <a href="https://www.umee.cc/careers" target='blank' className="text-typography-light mb-2 block hover:underline">
          Careers
        </a>
        <a href="https://drive.google.com/drive/folders/1A9G2HM5RAka4FLGyVvRC4NeazpAYBh7Z?usp=sharing" target='blank' className="text-typography-light mb-2 block hover:underline">
          Media Kit
        </a>
      </div>
    </div>
  </div>
)

export default Footer